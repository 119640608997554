var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _vm._m(1),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("See more help")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" Guideline ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/account" },
                    },
                    [_vm._v(" Create your account ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote" },
                    },
                    [_vm._v(" Request Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-save-condition" },
                    },
                    [_vm._v(" Saved condition function for Request Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-proposal" },
                    },
                    [
                      _vm._v(
                        " Request Quote from our proposal(recommend movie list) "
                      ),
                    ]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/quote-check" },
                    },
                    [_vm._v(" Check Quote ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/delivery" },
                    },
                    [_vm._v(" Delivery ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/email" },
                    },
                    [_vm._v(" Don't recieved a reset email? ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/vod/password" },
                    },
                    [_vm._v(" Lost Password? ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("Check Quote"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-9 flex flex-col justify-center sm:col-span-1" },
      [
        _c(
          "div",
          {
            staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
            attrs: { id: "help-vod-03" },
          },
          [
            _c("div", { staticClass: "px-6 pb-12" }, [
              _c("ul", { staticClass: "text-base" }, [
                _c("li", { staticClass: "mb-4" }, [
                  _c(
                    "div",
                    {
                      staticClass: "relative container overflow-hidden pb-16_9",
                    },
                    [
                      _c("iframe", {
                        staticClass:
                          "absolute top-0 left-0 right-0 w-9/12 h-full border-none mx-auto",
                        attrs: {
                          src: "https://www.youtube.com/embed/r1YFkZaazB8?rel=0&showinfo=0",
                          frameborder: "0",
                          allow:
                            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                          allowfullscreen: "",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("1.")]
                    ),
                    _vm._v(
                      " You can check your quote on your Filmitanion page."
                    ),
                    _c("br"),
                    _vm._v(" Click QUOTES. "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/03-01.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("2.")]
                    ),
                    _vm._v(" You can check the status for each quotes."),
                    _c("br"),
                    _vm._v(" ALL: All quotes"),
                    _c("br"),
                    _vm._v(
                      " WAITING QUOTE: Quotes under the review by contents holder or Filmination"
                    ),
                    _c("br"),
                    _vm._v(" NEGOTIATING: Quotes under negotiation"),
                    _c("br"),
                    _vm._v(
                      " WAITING UPLOAD:Quotes under waiting contents upload by contents holder."
                    ),
                    _c("br"),
                    _vm._v(
                      " DOWNLOADABLE:Quotes under donwload content is ready."
                    ),
                    _c("br"),
                    _vm._v(
                      " DOWNLOADED, WAITING STREAMING: Quotes under already donwload."
                    ),
                    _c("br"),
                    _vm._v(" STREAMING: Quotes currently streaming."),
                    _c("br"),
                    _vm._v(" TERMINATED STREAMING: Quotes terminated"),
                    _c("br"),
                    _vm._v(" CANCELLED: Cancelled quote"),
                    _c("br"),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/vod/03-02.jpg"),
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }